import {NgModule} from '@angular/core';
import {provideStates} from '@ngxs/store';
import {PersonState} from './person/person.state';
import {ProfileState} from './profile/profile.state';
import {SubmissionState} from './submission/submission.state';

@NgModule({
  providers: [provideStates([PersonState, ProfileState, SubmissionState])],
})
export class MicroservicesModule {}
